// image-text-vertical
.image-text-vertical {
  border-bottom: 1px solid $border-color;
  &:last-child {
      border-bottom: none;
  }
  // figure margin bottom
  .figure.block-fullwidth {
      margin:0 0 $spacer*2 0;
  }
  //
  h3 {
      margin-top: 24px;
  }
}

// text-image-vertical
.text-image-vertical {
  border-bottom: 1px solid $border-color;
  &:last-child {
      border-bottom: none;
  }
  // figure margin bottom
  .figure.block-fullwidth {
      margin:0 0 $spacer*2 0;
  }
  //
  h3 {
      margin-top: 8px;
  }
}

// image-text-horizontal
.image-text-horizontal {
  border-bottom: 1px solid $border-color;
  &:last-child {
      border-bottom: none;
  }
}

// autoblock-horizontal separateur version mobile/tablette
@media (max-width:$screen-sm-max) {
  .autoblock-horizontal {
      .col-md-3 {
          padding-top: 1em;
          padding-bottom: 1em;
          border-bottom: 1px solid $border-color;
          &:last-child {
              border-bottom: none;
          }
      }
  }
  .autoblock-vertical .image-text-horizontal {
      padding-top: 1em;
      padding-bottom: 1em;
      border-bottom: 1px solid $border-color;
      &:last-child {
          border-bottom: none;
      }
  }
}


.metadata-wrapper.border-bottom {
  font-size: 1.125em;
  font-family: $font-serif !important;
  position: relative;
  &:after {
      content: "";
      position: absolute;
      bottom: -1em;
      left: 6px;
      height: 1px;
      width: 100px;
      background: $text-color;
  }

}

[class*=autoblock-] {
  .metadata-wrapper .tag a {
      color: $h1-color;
      &:hover {
          color: $brand-color;
      }
  }

  p {
    color: $text-color;
  }
}

.autoblock-vertical {
  .only-text {
    border-bottom: none;
    padding: 0;

    h4 {
      &.reduce-title {
        font-size: 0.875rem !important;
        margin: 1rem 3rem 1rem 0;
        margin-right: 3rem;
        border-right: 1px solid #ddd;
        font-weight: bold;
      }

      &:after {
        font-family: 'FontAwesome';
        content: "\f178";
        position: absolute;
        right: 20px;
        top: 50%;
        color: #c41330;
        @include transform(translateY(-50%));
      }
    }
  }

  p {
      font-family: $font-sans;
  }

  .metadata-wrapper {
      font-size: 1em;
      [class*=metadata-] {
          font-family: $font-serif;
      }
  }

  .image-text-vertical {
      &.last-child {
          border-bottom: solid 1px $border-color;
      }
      .push-image {
          margin-bottom: 0;
      }
      .header-wrapper {
          padding-bottom: 20px;
          background: $white;
          .metadata-wrapper {
              margin-bottom: 10px;
              .time {
                  font-family: $font-serif;
                  font-style: italic;
                  font-size: 1em;
              }
          }
          h3 {
              font-size: 24px;
              font-weight: bold;
              margin-top: 10px;
          }

          .tag a {
            color: $primary-color;
          }
      }
  }

  .image-text-horizontal {
      border: 1px solid #ddd;
      padding: 4%;
      overflow: hidden;
      .header-wrapper {
          position: relative;
          .title-wrapper {
              position: absolute;
              bottom: -20px;
              right: 0;
              width: 80%;
              //tag of article
              p.tag {
                  padding: .75em 2em;
                  margin: 0;
                  width: 96%;
                  background: rgba(196, 19, 48, 0.6);
                  color: $white;
                  font-family: serif;
                  a {
                      color: $white;
                      text-decoration: none;
                      &:hover {
                          text-decoration: underline;
                      }
                  }
              }
              h3 {
                  background: #677693;
                  padding: 1em 2em;
                  font-size: 1em;
                  padding-right: 60px;
                  a {
                      color: $white;
                  }
              }
          }
      }
  }
}

.autoblock-horizontal {
  p {
    font-family: $font-sans;
  }

  .metadata-wrapper {
    font-size: 1em;
    [class*=metadata-] {
        font-family: $font-serif;
    }
  }

  .row {
    align-items: stretch;
  }

  .image-text-horizontal {
    .push-image {
        margin-bottom: 0;
        background-color: rgba($secondary-color, .2);
    }
    .header-wrapper {
      padding: 20px;
      padding-bottom: 40px;
      background: $white;

      .tag a {
        color: $primary-color;
      }

      .metadata-wrapper {
        margin-bottom: 10px;
        .time {
          font-family: $font-serif;
          font-style: italic;
          font-size: 1em;
        }
      }
      h3 {
        font-size: 24px;
        font-weight: bold;
        &, & * {
          color: #333;
        }
      }
    }
  }

  .image-text-vertical {
      &.last-child {
        border-bottom: solid 1px $border-color;
      }
      .push-image {
        margin-bottom: 0;
      }
      .header-wrapper {
        padding: 20px;
        padding-bottom: 40px;
        background: $white;
        .metadata-wrapper {
          margin-bottom: 10px;
          .time {
            font-family: $font-serif;
            font-style: italic;
            font-size: 1em;
          }
        }
        h3 {
          font-size: 24px;
          font-weight: bold;
        }
      }
  }
}

.highlight {
  .autoblock-title.reduce-title {
    font-size: 14px;
    line-height: 1.5;
  }
}

.only-text {
  .autoblock-title {
    &:hover {
      span {
        color: $primary-color !important;
      }
    }
  }
}

figure.push-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}