//title with backslash
.is-title-backslash {
  h1, h2 {
      font-family: $font-serif;
      margin-left: 36px;
      font-size: 2em;
  }
}

.is-title-backslash-s {
  h1, h2 {
    font-family: $font-serif;
    font-size: 1.5em !important;
    margin-left: 2em !important;
  }
}

//connection part title type
.is-title-bold {
  font-family: $font-sans !important;
  font-style: normal !important;
  font-weight: bold !important;
  margin-left: 0 !important;
  font-size: 2em !important;
  &:before {
      display: none;
  }
}