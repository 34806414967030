.newsletter-wrapper {
  padding: 2em 1em;
  .newsletter-title {
      &:before {
          display: none !important;
      }
      .is-h1{
          font-family: $font-serif;
          font-size: 1.5em;
          margin-left: 20px;
          margin-top: 0;
        //   &:before {
        //       content: '';
        //       border-right: 1px solid $white;
        //       display: inline-block;
        //       height: 30px;
        //       margin-left: -15px;
        //       position: absolute;
        //       @include transform(rotate(-20deg));
        //   }
      }
      .is-h2 {
          font-size: 1.5em;
          text-transform: uppercase;
          display: block;
          margin: 10px 0 20px;
      }
  }

  .form-control {
      height: 35px;
      padding: 6px 10px;
  }

  input {
      border: none;
      border-bottom: 1px solid $white;
      background-color: transparent;
      box-shadow: none;
      margin: $grid-gutter-width 0 $grid-gutter-width*2;
      font-family: $font-sans;
      color: $white;
      &::placeholder {
        color: $white;
      }
      &:focus {
          box-shadow: none;
          border-color: $white;
          color: $text-color;
      }
  }

  .notification, label {
      font-family: $font-sans;
  }

  select, option {
      color: $text-color;
      font-family: $font-sans;
  }

  a.btn {
      font-weight: 700;
      text-transform: uppercase;
  }

  button.btn {
      border-color: $white;
      font-weight: 400;
  }
}