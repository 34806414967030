.product-block {
    .card {
        border-radius: 0;
    }

    .card-title {
        font-size: 2.25rem;
        font-weight: lighter;
        text-transform: uppercase;
    }

    a {
        font-weight: bold;
        font-size: 1.25rem;
    }

    .feature-list {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;

            li {
                display: flex;
                align-items: center;
                margin-bottom: .25rem;

                &::before {
                    content: '◆';
                    color: $primary-color;
                    font-size: .75rem;
                    margin-right: .5rem;
                }
            }
        }
    }
}