.offer-checkout {
  font-family: $font-sans;
  .form-control {
    height: 35px;
    padding: 6px 10px;
  }
  .row {
    .checkout-step-item {
      margin-bottom: $grid-gutter-width;
      .bg {
        overflow: hidden;
        padding: 2em !important;
      }
      .form-horizontal {
        float: none;
      }
      .form-label {
        margin-top: 20px;
        margin-bottom: 10px;
        font-weight: 400;
      }
      label.radio-inline {
        font-weight: 500;
      }
      .payment-logo {
        background: url(../img/logos_payment.png) no-repeat;
        width: 100px;
        height: 1.3em;
        vertical-align: middle;
        margin-left: 7px;
        background-size: contain;
        display: inline-block;
      }

      i.fa {
        vertical-align: sub;
        margin-left: 10px;
      }
    }
  }

  .offer-info {
    padding: 20px;
    background: $menu-bg;
    position: relative;

    @include media-breakpoint-up(lg) {
      &:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 8px 6px 0;
        border-color: transparent #e8ebf0 transparent transparent;
        position: absolute;
        left: -8px;
        top: 50%;
      }
    }

    .is-title {
      text-transform: uppercase;
      font-weight: 500;
    }

    header {
      &:before {
        display: none !important;
      }
      h2 {
        color: $brand-color;
        font-size: 28px;
        margin-left: 24px !important;
      }
    }

    .offer-details {
      list-style: none;
      padding: 0;
      margin: 0;
      li + li:before {
        content: "+ ";
      }
    }

    .cloud-liner:after {
      border-color: #cacaca;
    }

    .offer-price {
      line-height: 1;
      .price-value {
        font-size: 3em;
        font-weight: bold;
        color: $brand-color;
      }
    }
  }

  .change-offer {
    padding-bottom: 20px;
    margin-bottom: 10px;
  }

  @include media-breakpoint-up(lg) {
    .small-col {
      position: relative;
      .sidebar {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }

    .sticky-sidebar {
      position: -webkit-sticky;
      position: sticky;
      top: 80px;
    }
  }

  @include media-breakpoint-down(md) {
    .subscribe {
      flex-direction: column-reverse;
    }
  }
}

//background color for e-commerce bloc
#cloud-ui .tab.offer_title {
  .element_color button {
      &.bg-color5 {
          background: $bg-footer-copyright !important;
      }
      &.bg-color7 {
          background: $brand-color-2 !important;
      }
      &.bg-color8 {
          background: $menu-bg !important;
          padding: 15px;
          i {
              display: none;
          }
      }
  }
  .cloud-select {
      display: block;
  }
}


//front end e-commerce bloc
.bb-content.offer {
  font-family: $font-sans;
  margin: 0 !important;
  padding: 70px 0 0 !important;
  .tag-onsale {
      top: 20px;
      background: #fff;
      box-shadow: 0 -5px 20px #dadada;
      padding: 5px;
      border-radius: 5px 5px 0 0;
      width: 90%;
      margin: 0 auto;
      height: 50px;
      p {
          margin: 0;
          line-height: 1;
      }
  }

  .offer-content {
      border: 1px solid $border-color;
      .title-wrapper {
          padding: 1em 1em 2.5em;
          &.bg-color5 {
              background: $bg-footer-copyright;
              color: $white;
              h2 {
                  color: $white;
              }
          }
          &.bg-color7 {
              background: $brand-color-2;
              color: $white;
              h2 {
                  color: $white;
              }
          }
          &.bg-color8 {
              background: $menu-bg;
          }
          h2 {
              font-family: $font-serif;
              font-size: 1.5em;
          }
          .price-wrapper {
              display: table;
              margin: 0 auto;
              strong {
                  text-transform: uppercase;
                  font-size: .875em;
              }
              p.offer-free {
                  font-size: 3em;
                  text-align: center;
              }
              .price {
                  line-height: 1;
                  font-weight: 700;
                  .price-value {
                      font-size: 4em;
                      display: inline-block;
                  }
                  .price-info {
                      display: inline-block;
                      span {
                          display: block;
                          font-size: 1.5em;
                          text-transform: uppercase;
                      }
                      .offer-duration:before {
                          content: '/';

                      }
                  }
              }
          }
      }

      .details-wrapper {
          ul {
              padding: 0;
              margin: 20px;
              list-style: none;
              li {
                  padding: 10px 0;
              }
          }
      }

      .button-wrapper {
          margin: 20px 0;
      }
  }
}