/* Les Cahiers d'Ophralmologie - 2019 janvier - By Lp digital*/
/* Custom styles below */

// Animate CSS
@import '../node_modules/animate.sass/scss/animate.scss';

// Vendor css
@import '../node_modules/sweetalert2/src/sweetalert2.scss';
@import '../node_modules/trumbowyg/dist/ui/sass/trumbowyg.scss';
@import 'custom_partials/libs/remodal';
@import 'custom_partials/libs/remodal-default-theme';

// Custom partials
@import 'custom_partials/socialNetwork';

body {
  // nav sticky height
  @media (min-width:$screen-sm-min) {
      padding-top: $nav-height-sticky + 50;
  }
  @media (max-width:$screen-xs-max) {
      padding-top: $nav-height-sticky + $spacer-half;
  }

  @media (min-width:$screen-md-min) {
      .col-md-8, .col-md-9 {
          padding-right: 5%;
      }
  }

  // Custom partials
  @import 'custom_partials/reset';
  @import 'custom_partials/modal';
  @import 'custom_partials/switch';
  @import 'custom_partials/titleStyle';
  @import 'custom_partials/autoblock';
  @import 'custom_partials/articlePage';
  @import 'custom_partials/headerNav';
  @import 'custom_partials/imageLibrary';
  @import 'custom_partials/offer';
  @import 'custom_partials/catalog';
  @import 'custom_partials/calendar';
  @import 'custom_partials/pub';
  @import 'custom_partials/signinNewsletter';
  @import 'custom_partials/restricted';
  @import 'custom_partials/slider';
  @import 'custom_partials/CardProduct';
  @import 'custom_partials/tarteaucitron';

  /*
  CUSTOM CSS
  */
  .main {
      @media (max-width:$screen-sm-max) {
        margin-top: 0;
      }
      .large-col.col-md-8, .large-col.col-md-9, .large-col.col-md-12, .large-col.col-lg-9 {
        header:not(.article-header) {
          h1 {
            font-family: $font-serif;
            margin-left: 36px;
            font-size: 2em;
          }
        }
      }

      .small-col {
          header {
            h1 {
              font-family: $font-serif;
              font-size: 1.5em;
              margin-left: 16px;
            }
          }
          .autoblock-vertical {
            .row h3 {
              margin: 1em;
              margin-right: 3em;
              border-right: 1px solid $border-color;
              a {
                font-weight: bold;
              }
              &:after {
                font-family: 'FontAwesome';
                content: "\f178";
                position: absolute;
                right: 20px;
                top: 36%;
                color: $brand-color;
              }
            }
          }
      }

      @import 'custom_partials/search';
      @import 'custom_partials/account';
  }

    /*
  FOOTER
  */
  .footer {
    margin-top: $spacer*2;
    color: $white;
    margin-top: 0;
    .social-list a:hover {
      background: transparent;
      color: $brand-color !important;
    }
    .form-group, .bb-content {
      margin-bottom: 0;
    }

    a {
      color: $white;
      i {
        margin-right: 10px;
        font-size: 1.5em;
      }
    }
    .footer-main {
      background-color: $bg-footer;
      padding: 0;
      .footer-item-container {
        padding-top: 50px;
        padding-bottom: 50px;
        .footer-item {
          border-right: 1px solid #696d73;
          text-transform: uppercase;
          padding-left: 30px;
          padding-right: 30px;
        }
      }

      p {
        padding: 0;
        margin-top:0;

        a {
          color: $white;
        }
      }
      .quick-access-footer {
        text-transform: uppercase;
        padding-left: 30px;
        padding-right: 30px;
        ul {
          padding-top: 1em;
          padding-left: 0;
          list-style: none;
          li {
            margin-bottom: 2em;
          }
        }
      }
    }

    .footer-copyright {
      background-color: $bg-footer-copyright;
      font-weight: 600;
      text-transform: none;
      .footer-right {
        float: right;
        text-align: right;
        .bb-content {
          margin-bottom: 0;
          span {
            display: inline-block;
            margin-right: 10px;
            font-size: 16px;
            text-transform: none;
          }
          ul {
            display: inline-block;
            li {
                padding: 0 5px;
                a {
                    line-height: inherit;
                    width: auto;
                    height: auto;
                    color: $white;
                    border: none;
                    i {
                        font-size: 16px;
                    }
                }
            }
          }
        }
      }
    }
  }
}
