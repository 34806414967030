.calendar-showup {
  .carousel-item {
      padding: 15px;
      min-height: 120px;
      time {
          font-style: italic;
          font-family: 'Noto Serif', serif;
          margin-bottom: 16px;
          color: $text-color;
      }
      h3 {
          font-size: 1.125em;
      }

      .description {
          margin-top: 16px;
      }

      p {
          color: $text-color !important;
      }

      a {
          color: $primary-color;

          &:hover {
              color: $text-color;
              text-decoration: underline;
          }
      }
  }
  .carousel-navigation {
      text-align: right;
      .carousel-control {
          background: #555 !important;
          position: relative;
          text-shadow: none;
          display: inline-block;
          padding: 6px 12px;
      }
  }
}

.calendar-homepage {
    margin: $grid-gutter-width 0;
    .calendar-item-wrapper {
        border-bottom: 1px solid;
        margin-bottom: 30px;
        padding-bottom: 15px;
        .metadata-wrapper {
          font-size: 1.125em;
          font-family: $font-serif !important;
          position: relative;
        }
        h3 {
            padding: $grid-gutter-width/3 0;
            font-weight: bold;
        }
        p {
            font-family: $font-sans;
        }
    }

    .place {
        color: $primary-color;
    }
}

.calendar-panel {
    margin: $grid-gutter-width 0;
    .calendar-item-wrapper {
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid;

        .metadata-wrapper {
          font-size: 1.125em;
          font-family: $font-serif;
          position: relative;
        }

        h3 {
            padding: $grid-gutter-width/3 0;
        }

        .description a {
            color: $primary-color
        }
    }
}