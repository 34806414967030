.figure.block-fullwidth {
  margin:0 0 $spacer 0;
}
// metadata with border top
.metadata-wrapper.border-top {
  padding-top: 10px;
  margin-top: 10px;
}
.clearboth {
  clear: both;
}

.paragraph, .lead {
  font-family: $font-sans;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.cloud-hidden {
  display: none;
}

p {
  line-height: 1.5;
}

h1,
h2,
h3 {
  color: $text-color !important;
}

.form-group {
  overflow: hidden;
  &.required {
      label.control-label:after {
          content:"*";
      }
  }

  .control-label {
      font-family: $font-sans;
      font-weight: 400;
      color: $brand-color-2;
  }
}

.owl-carousel .slider-item .slider-content {
  background: rgba(196, 19, 48, 0.6);
  left: 10%;
  right: 2px;
  top: auto;
  bottom: 0;
  text-align: left;
  padding: 0;
  @include transform(translateY(0));
  [data-parameter-name="title"] {
      font-size: 1.5em;
      font-family: $font-serif;
  }
  p {
      margin-bottom: 0;
  }
  .lead {
      padding: .5em 2em;
      margin: 0;
  }
}

i.icon_pen {
  content: url(../img/Pen.svg);
}

@include media-breakpoint-up(md) {
  .cloud-edit {
      .navbar.navbar-fixed-top.add-padding {
          margin-left: 80px //toolbar
      }
  }

  .is-desktop.small-screen .cloud-edit .navbar.navbar-fixed-top.add-padding {
      margin-left: 60px //toolbar
  }
}

.form-control {
  border-radius: 0;
  &.half-width {
      width: 49%;
      float: left;
  }
  &.float-right {
      float: right;
  }

  &:focus {
    box-shadow: none;
  }
}

.g-recaptcha > div {
  margin: unset;
}

.bb-content {
  .icon-search {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 56.966 56.966' style='enable-background:new 0 0 56.966 56.966;' xml:space='preserve' fill='white'%3E%3Cpath d='M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23 s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92 c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17 s-17-7.626-17-17S14.61,6,23.984,6z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    height: 20px;
    width: 16px;
    display: block;

    &:before {
      display: none;
    }
  }
}

//globle button
.bb-site-wrapper{
  .btn {
    font-family: $font-sans;
    text-transform: uppercase;
    font-weight: bold;
    border-width: 1px;

    &.btn-primary {
      &, & * {
        color: $white !important;
      }
    }
  }

  .form-action {
    .btn {
      padding: 1rem 5rem;
    }
  }

  .no-radius {
    border-radius: 0;
  }

  .btn-radius {
    border-radius: 3px;
  }

  .btn-brand {
    a.btn {
      @extend .btn-primary;
      padding: 15px 10px;
      width: 100%;
      font-size: 22px;
      font-weight: 400;
    }

    .btn.btn-primary {
      .btn-brand-text {
        display: block;
        text-transform: none;
        font-size: 2rem;

        &,
        & * {
          color: black !important;
        }

        span {
          font-size: .75rem;
          padding: 2px;
          background-color: #000;
          color: #fff!important;
          text-transform: uppercase;
          line-height: 1;
        }
      }
    }
  }

  .metadata-wrapper {
    text-transform: initial;

    .metadata-tag {
      padding: 0;
      text-transform: capitalize;
      border: none;
    }

    a {
      color: $primary-color;

      &:hover {
        color: $text-color;
      }
    }
  }

  .breadcrumb {
    color: #677693;
    font-family: $font-sans;
    margin: 10px 0 0;
    display: block;
    background-color: transparent;
  }

  .title {
    margin: 40px 0;
  }

  .metadata-author {
    color: $brand-color;
  }

  .bb-content.quote {
    .quote-text {
        margin: 0 2em;
        padding: 0 1em;
        border-left: 2px solid $brand-color;
        color: $brand-color;
        &.text-right {
            border-left: 0;
            border-right: 2px solid $brand-color;
        }
    }
  }
}

#bb-site-wrapper.blur {
  filter: unset;
}

.bb-content.media-image {
  .contentwrapper .textwrapper {
    background: rgba(196, 19, 48, 0.6);
    padding: .5em 2em;
    left: 10%;
    right: 2%;
    .is-h3 {
      color: $white;
      &[data-parameter-name="title"] p {
        font-family: $font-serif;
      }
      &[data-parameter-name="description"] {
        font-size: 1em;
      }
    }
  }
}
.is-h1,
.is-h2,
.is-h3,
.is-h4,
.is-h5,
.is-h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  &:hover {
    text-decoration: none;
    @include transition($btn-transition);
  }
}

h3, .is-h3 {
  font-size: 1.45rem;

  a:hover {
    color: $primary-color;
    text-decoration: none;
  }
}

a:hover {
  text-decoration: none;
}

.paragraph a {
  &, & span {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.fixed-bottom, .fixed-top {
  z-index: 2;
}

.navbar-header {
  height: unset;
}

.has-bg-color {
  &.color7 {
      @include bg-color($light-color);
  }
}

.rte-text a {
  color: $primary-color;

  &:hover {
    text-decoration: none;
    color: $text-color;
  }
}

.figure.media-image .contentwrapper .above-content-wrapper {
  padding: 0 !important;
  z-index: 1;

  .is-h3 {
    font-weight: 400 !important;
    padding: 0 !important;

    p {
      line-height: 1.2;
    }
  }

  figcaption {
    margin-bottom: 0 !important;
  }

  .textwrapper .is-h3[data-parameter-name=title] {
    margin-bottom: 5px;
  }
}

.form-inline {
  label {
    @include media-breakpoint-up(sm) {
      justify-content: flex-end;
      text-align: right;
    }
  }

  .form-group {
    margin-bottom: 1rem;

    input:not([type=checkbox]):not([type=radio]), select {
      width: 100%;
    }
  }
}

.footer .social-list {
  margin-bottom: 0;
}